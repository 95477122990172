var bezierEasing = require('bezier-easing');

/*
    Ease-In-Out animation
*/
function MoveAnimation(from, to, duration) {
  this._easing = bezierEasing(0.42, 0.0, 0.58, 1.0);

  this._from = from;
  this._length = to - from;
  this._duration = duration;
}

MoveAnimation.prototype.tick = function(time) {
  var t = time / this._duration;

  if (t >= 1) {
    return {
      pos: this._from + this._length,
      end: true,
    };
  }

  return {
    pos: this._from + this._length * this._easing(t),
    end: false,
  };
};

/*
    Overdamped spring-mass system animation.
    http://ariya.ofilabs.com/2013/11/javascript-kinetic-scrolling-part-2.html
*/
function PushAnimation(from, to) {
  this._to = to;
  this._length = to - from;

  // Makes the slider feel light or heavy
  this._TIME_CONSTANT = 325;
}

PushAnimation.prototype.tick = function(time) {
  // A typical flick list has a deceleration that is a form of exponential decay.
  // https://en.wikipedia.org/wiki/Exponential_decay
  var delta = this._length * Math.exp(-time / this._TIME_CONSTANT);
  if (Math.abs(delta) < 0.5) {
    return {
      pos: this._to,
      end: true,
    };
  }

  return {
    pos: this._to - delta,
    end: false,
  };
};

module.exports.MoveAnimation = MoveAnimation;
module.exports.PushAnimation = PushAnimation;
