var DURATION = 150;

/**
 * Show or hide element-button depending on scroll position and page width
 */
function ScrollButton(element) {
  this._element = element;

  this._animation = null;
  this._raf = null;
  this._headerBottom = 0;
  this._enabled = false;

  var rows = document.getElementsByClassName('header-row');
  if (rows.length !== 1) {
    // Error
    return;
  }
  this._headerRow = rows[0];

  this._checkButton = this._checkButton.bind(this);
  this._onResize = this._onResize.bind(this);

  window.addEventListener('resize', this._onResize);

  this._onResize();
}

// Recalculate this._headerBottom to reduce DOM calls in other operations
ScrollButton.prototype._recalc = function() {
  var rect = this._headerRow.getBoundingClientRect();

  this._headerBottom = rect.bottom + window.pageYOffset;
};

// Enabled only in mobile view
ScrollButton.prototype._onResize = function() {
  this._recalc();

  if (window.matchMedia('(min-width: 640px)').matches) {
    // Disable
    if (!this._enabled) {
      return;
    }

    this._enabled = false;

    this._element.style.display = null;
    this._element.style.opacity = null;

    window.removeEventListener('scroll', this._checkButton);

    return;
  }

  if (this._enabled) {
    this._checkButton();
    return;
  }

  // Enable
  this._enabled = true;

  // Init state
  if (window.pageYOffset > this._headerBottom) {
    this._isButtonVisible = true;

    this._element.style.display = 'inline-block';
    this._element.style.opacity = 1;
  } else {
    this._isButtonVisible = false;

    this._element.style.display = 'none';
    this._element.style.opacity = 0;
  }

  window.addEventListener('scroll', this._checkButton);
};

// Show or hide button depending on scroll position
ScrollButton.prototype._checkButton = function() {
  var isButtonVisible = window.pageYOffset > this._headerBottom;
  if (isButtonVisible == this._isButtonVisible) {
    return;
  }

  if (isButtonVisible) {
    this._show();

    return;
  }

  this._hide();
};

// Animation body
ScrollButton.prototype._draw = function(timestamp) {
  var t = (timestamp - this._animation.startTime) / this._animation.duration;
  if (t >= 1) {
    this._element.style.opacity = this._animation.to;
    if (!this._animation.to) {
      this._element.style.display = 'none';
    }

    this._raf = null;
    this._animation = null;
    return;
  }

  this._element.style.opacity =
    this._animation.from + (this._animation.to - this._animation.from) * t;

  this._raf = requestAnimationFrame(this._draw.bind(this));
};

// Get element's current opacity
ScrollButton.prototype._getOpacity = function() {
  if (!this._element.style.opacity) {
    return 1.0;
  }

  var opacity = parseFloat(this._element.style.opacity);
  if (isNaN(opacity)) {
    return 0.0;
  }

  return opacity;
};

// Start hiding
ScrollButton.prototype._hide = function() {
  this._isButtonVisible = false;

  var opacity = this._getOpacity();
  var duration = DURATION * opacity;

  this._animation = {
    from: opacity,
    to: 0,
    duration: duration,
    startTime: window.performance.now ? performance.now() : Date.now(),
  };

  if (!this._raf) {
    this._raf = requestAnimationFrame(this._draw.bind(this));
  }
};

// Start showing
ScrollButton.prototype._show = function() {
  this._isButtonVisible = true;
  this._element.style.display = 'inline-block';

  var opacity = this._getOpacity();
  var delta = 1 - opacity;
  var duration = DURATION * delta;

  this._animation = {
    from: opacity,
    to: 1,
    duration: duration,
    startTime: window.performance.now ? performance.now() : Date.now(),
  };

  if (!this._raf) {
    this._raf = requestAnimationFrame(this._draw.bind(this));
  }
};

module.exports = ScrollButton;
