/*
    Slide element within parent bounds
*/
function Slider(element) {
  this._element = element;

  this.pos = 0;

  // Deal with prefixes
  var transform = 'transform';
  ['webkit', 'Moz', 'O', 'ms'].every(function(prefix) {
    var prefixed = prefix + 'Transform';
    if (element.style[prefixed] !== undefined) {
      transform = prefixed;
      return false;
    }

    return true;
  });
  this._transform = transform;

  this.updateDimensions();
}

// Check pos to be inside bounds
Slider.prototype.checkBounds = function(pos) {
  if (pos > 0) {
    return 0;
  }

  if (pos < this.max) {
    return this.max;
  }

  return pos;
};

// Update dimmensions
Slider.prototype.updateDimensions = function() {
  var rect = this._element.getBoundingClientRect();
  var parentRect = this._element.parentElement.getBoundingClientRect();

  if (rect.width < parentRect.width) {
    this.max = 0;
    return;
  }

  this.max = parentRect.width - rect.width;
};

// Move element by dx pixels
Slider.prototype.moveBy = function(dx) {
  this.pos = this.checkBounds(this.pos + dx);
};

// Move element to x pixel
Slider.prototype.moveTo = function(x) {
  this.pos = this.checkBounds(x);
};

// Apply
Slider.prototype.draw = function() {
  this._element.style[this._transform] = 'translateX(' + this.pos + 'px)';
};

module.exports = Slider;
