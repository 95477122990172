require('es6-promise').polyfill();
require('whatwg-fetch');

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

function toISODate(date) {
  return date.getUTCFullYear() + '-' + pad(date.getUTCMonth() + 1) + '-' + pad(date.getUTCDate());
}

function createElement(type, className, innerText) {
  var elem = document.createElement(type);
  if (className) {
    elem.classList.add(className);
  }

  if (innerText) {
    elem.innerText = innerText;
  }

  return elem;
}

/**
 * Create grid cell
 * @param params - id, absolute_url, imgX1_url, imgX2_url, projectType, name, description, location, date
 */
function createCell(params) {
  var cell = createElement('div', 'portfolio-cell');

  var aElem = createElement('a');
  aElem.href = params.absolute_url;
  cell.appendChild(aElem);

  var content = createElement('div', 'portfolio-cell-content');
  aElem.appendChild(content);

  var image = createElement('img', 'portfolio-cell-image');

  image.src = params.imgX1_url;
  image.setAttribute('srcset', params.imgX1_url + ' 1x, ' + params.imgX2_url + ' 2x');
  image.setAttribute('alt', params.name);

  // * Cell info
  var cellInfo = createElement('div', 'portfolio-cell-info');

  var projectType = createElement('h1', 'portfolio-cell-type', params.projectType);

  // ** Info center
  var infoCenter = createElement('div', 'portfolio-cell-info-center');

  var cellName = createElement('h2', 'portfolio-cell-name', '«' + params.name + '»');
  var cellDescription = createElement('p', 'portfolio-cell-description', params.description);
  var cellLocation = createElement('p', 'portfolio-cell-location', params.location);
  infoCenter.appendChild(cellName);
  infoCenter.appendChild(cellDescription);
  infoCenter.appendChild(cellLocation);
  // **

  var date = new Date(params.date);
  var cellYear = createElement('p', 'portfolio-cell-year', date.getFullYear());

  cellInfo.appendChild(projectType);
  cellInfo.appendChild(infoCenter);
  cellInfo.appendChild(cellYear);
  // *

  content.appendChild(image);
  content.appendChild(cellInfo);

  return cell;
}

function GridScroller(target, id, date, category) {
  this._category = category;
  this._target = target;
  this._fetching = false;
  this._endOfData = false;

  this._latestData = { id: id, date: new Date(date) };

  this._footerHeigth = 158;

  this.fillSpace();

  window.addEventListener(
    'scroll',
    function() {
      this.fillSpace();
    }.bind(this)
  );
}

GridScroller.prototype.fillSpace = function(cb) {
  if (this._endOfData || this._fetching) {
    return;
  }

  if (window.innerHeight + window.scrollY < document.body.scrollHeight - this._footerHeigth) {
    return;
  }

  this._fetching = true;
  id = this._latestData.id;
  date = this._latestData.date;

  var query = '/projects?id=' + id + '&date=' + toISODate(date);
  if (this._category) {
    query += '&category=' + this._category;
  }

  var self = this;
  fetch(query)
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      if (!data.length) {
        self._endOfData = true;
        self._fetching = false;
        return;
      }

      for (var i = 0; i < data.length; i++) {
        self.addCell(data[i]);
      }

      var lastData = data[data.length - 1];
      self._latestData = { id: lastData.id, date: new Date(lastData.date) };
      self._fetching = false;

      self.fillSpace();
    });
};

GridScroller.prototype.addCell = function(params) {
  this._target.appendChild(createCell(params));
};

module.exports = GridScroller;
