var Slider = require('./slider');
var MoveAnimation = require('./animations').MoveAnimation;
var PushAnimation = require('./animations').PushAnimation;

/*
    Slider with animations, debounce drawing and snap points
*/
function AnimatedSlider(element) {
  this._slider = new Slider(element);

  this._rAF = null;

  this.updateSnapPoints([]);
}

// Update slider or/and animate
AnimatedSlider.prototype._draw = function(timestamp) {
  if (this._animationData) {
    var time = timestamp - this._animationData.startTime;
    var res = this._animationData.animation.tick(time);

    this._slider.moveTo(res.pos);

    if (res.end) {
      this._animationData = null;
    }
  }

  this._slider.draw();

  if (!this._animationData) {
    this._rAF = null;
    return;
  }

  this._rAF = requestAnimationFrame(this._draw.bind(this));
};

// Schedule animation if not already
AnimatedSlider.prototype._schedule = function(animation) {
  this._animationData =
    animation === undefined
      ? null
      : {
          animation: animation,
          startTime: window.performance.now ? performance.now() : Date.now(),
        };

  if (!this._rAF) {
    this._rAF = requestAnimationFrame(this._draw.bind(this));
  }
};

// Find closest snap point to pos
AnimatedSlider.prototype._findClosestPoint = function(pos) {
  var closestPoint = this._snapPoints[0];
  for (var i = 1; i < this._snapPoints.length; ++i) {
    if (Math.abs(pos + this._snapPoints[i]) < Math.abs(pos + closestPoint)) {
      closestPoint = this._snapPoints[i];
    }
  }

  return -closestPoint;
};

// Update slider dimmensions and snap points
AnimatedSlider.prototype.updateSnapPoints = function(snapPoints) {
  this._slider.updateDimensions();

  this._snapPoints = [];
  for (var i = 0; i < snapPoints.length; ++i) {
    // Skip points which can not be reached
    if (snapPoints[i] > Math.abs(this._slider.max)) {
      continue;
    }

    this._snapPoints.push(snapPoints[i]);
  }

  // max - is last snap point
  this._snapPoints.push(Math.abs(this._slider.max));
};

// Slider pos
AnimatedSlider.prototype.getPos = function() {
  return this._slider.pos;
};

// Move slider by dx pixels
AnimatedSlider.prototype.moveBy = function(dx) {
  this._slider.moveBy(dx);
  this._schedule();
};

// Move to next snap point
AnimatedSlider.prototype.moveRight = function() {
  if (!this._snapPoints.length) {
    return;
  }

  var pointIndex = this._snapPoints.length - 1;
  for (var i = 0; i < this._snapPoints.length; ++i) {
    point = this._slider.pos + this._snapPoints[i];

    if (point > 30) {
      pointIndex = i;
      break;
    }
  }

  if (this._slider.pos == -this._snapPoints[pointIndex]) {
    return;
  }

  this._schedule(new MoveAnimation(this._slider.pos, -this._snapPoints[pointIndex], 500));
};

// Move to prev snap point
AnimatedSlider.prototype.moveLeft = function() {
  if (!this._snapPoints.length) {
    return;
  }

  var pointIndex = 0;
  for (var i = this._snapPoints.length - 1; i >= 0; --i) {
    point = this._slider.pos + this._snapPoints[i];

    if (point < -30) {
      pointIndex = i;
      break;
    }
  }

  if (this._slider.pos == -this._snapPoints[pointIndex]) {
    return;
  }

  this._schedule(new MoveAnimation(this._slider.pos, -this._snapPoints[pointIndex], 500));
};

// Push slider with velocity
AnimatedSlider.prototype.push = function(velocity) {
  // If you want to make the scroller feel "heavy" reduce the AMPLITUDE_FACTOR
  var AMPLITUDE_FACTOR = 0.3;

  var targetPos = this._slider.pos + AMPLITUDE_FACTOR * velocity;
  var snapPoint = this._findClosestPoint(targetPos);
  if (this._slider.pos == snapPoint) {
    return;
  }

  this._schedule(new PushAnimation(this._slider.pos, snapPoint));
};

// Adjust slider pos to closest snap point
AnimatedSlider.prototype.adjust = function() {
  var targetPos = this._slider.pos;
  var snapPoint = this._findClosestPoint(targetPos);
  if (this._slider.pos == snapPoint) {
    return;
  }

  this._schedule(new MoveAnimation(this._slider.pos, snapPoint, 250));
};

module.exports = AnimatedSlider;
