/*
    Measure value change speed
*/
function Speedometer(getValue) {
  this._getValue = getValue;

  this._timer = null;

  this._lastTime = 0;
  this._lastValue = 0;
  this.velocity = 0;
}

Speedometer.prototype._measure = function() {
  var now = window.performance.now ? performance.now() : Date.now();
  var dt = (now - this._lastTime) / 1000;
  this._lastTime = now;

  var value = this._getValue();
  var dv = value - this._lastValue;
  this._lastValue = value;

  // Apply moving average filter
  var alpha = 0.8;
  this.velocity = (alpha * dv) / dt + (1 - alpha) * this.velocity;
};

Speedometer.prototype.start = function() {
  this.velocity = 0;
  this._lastTime = window.performance.now ? performance.now() : Date.now();
  this._lastValue = this._getValue();

  var MEASURE_INTERVAL = 30;
  this._timer = setInterval(this._measure.bind(this), MEASURE_INTERVAL);
};

Speedometer.prototype.stop = function() {
  if (!this._timer) {
    return;
  }

  clearInterval(this._timer);
  this._timer = null;
};

module.exports = Speedometer;
