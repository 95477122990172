var bezierEasing = require('bezier-easing');

var EASING = bezierEasing(0.17, 0.67, 0.83, 0.67);
var DURATION = 350;

/**
 * Page scroll button
 */
function PageScroller(element) {
  this._element = element;
  this._element.addEventListener('click', this.scrollUp.bind(this));

  // Scroll animation
  this._fromY = 0;
  this._startTime = 0;
  this._raf = null;
}

// Do scrolling
PageScroller.prototype._scroll = function(timestamp) {
  var t = (timestamp - this._startTime) / DURATION;
  if (t >= 1) {
    // End animation
    window.scrollTo(0, 0);

    this._raf = null;
    this._fromY = 0;
    this._startTime = 0;
    return;
  }

  window.scrollTo(0, this._fromY - this._fromY * EASING(t));

  this._raf = requestAnimationFrame(this._scroll.bind(this));
};

// Scroll the page to top
PageScroller.prototype.scrollUp = function() {
  if (this._raf) {
    return;
  }

  this._fromY = window.scrollY;
  this._startTime = window.performance.now ? performance.now() : Date.now();
  this._raf = requestAnimationFrame(this._scroll.bind(this));
};

module.exports = PageScroller;
