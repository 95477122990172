var AnimatedSlider = require('./animated_slider');
var Speedometer = require('./speedometer');

/*
    Project slider
*/
function ProjectSlider() {
  this._lastX = null;
  this._inTouchScroll = false;

  var portfolioSlides = document.getElementById('portfolio-slides');
  this._slider = new AnimatedSlider(portfolioSlides);

  // Mouse interaction
  portfolioSlides.addEventListener('mousedown', this._onPress.bind(this));
  portfolioSlides.addEventListener('mousemove', this._onMove.bind(this));
  portfolioSlides.addEventListener('mouseup', this._onRelease.bind(this));

  // Touch interaction
  portfolioSlides.addEventListener('touchstart', this._onTouchStart.bind(this));
  portfolioSlides.addEventListener('touchmove', this._onTouchMove.bind(this));
  portfolioSlides.addEventListener('touchend', this._onTouchEnd.bind(this));

  // Speedometer
  this._speedometer = new Speedometer(
    function() {
      return this._slider.getPos();
    }.bind(this)
  );

  // Buttons
  var buttonNext = document.getElementById('portfolio-slides-button-next');
  buttonNext.addEventListener('click', this._slider.moveRight.bind(this._slider));

  var buttonPrev = document.getElementById('portfolio-slides-button-prev');
  buttonPrev.addEventListener('click', this._slider.moveLeft.bind(this._slider));

  // Update snap points while loading
  this._updateSnapPoints();
  this._loadTimer = setInterval(this._updateSnapPoints.bind(this), 300);
  window.addEventListener(
    'load',
    function() {
      clearInterval(this._loadTimer);
      this._loadTimer = null;

      this._updateSnapPoints();
    }.bind(this)
  );

  // Update snap points when resizing
  this._resizeTimeout = null;
  window.addEventListener(
    'resize',
    function() {
      // Skip if load is not finished
      if (this._loadTimer) {
        return;
      }

      // Debaounce
      if (this._resizeTimeout) {
        clearTimeout(this._resizeTimeout);
        this._resizeTimeout = null;
      }

      this._resizeTimeout = setTimeout(this._updateSnapPoints.bind(this), 100);
    }.bind(this)
  );
}

// Calculate snap points
ProjectSlider.prototype._updateSnapPoints = function() {
  var portfolioSlides = document.getElementById('portfolio-slides');

  var offset = portfolioSlides.getBoundingClientRect().left;

  var snapPoints = [];
  for (var i = 0; i < portfolioSlides.children.length; i++) {
    var x = portfolioSlides.children[i].getBoundingClientRect().left;
    snapPoints.push(x - offset);
  }
  this._slider.updateSnapPoints(snapPoints);
};

// Mouse handlers
ProjectSlider.prototype._onPress = function(e) {
  this._lastX = e.pageX;

  this._speedometer.start();
};

ProjectSlider.prototype._onMove = function(e) {
  if (this._lastX === null) {
    return;
  }

  var diff = e.pageX - this._lastX;
  this._slider.moveBy(diff);

  this._lastX = e.pageX;
};

ProjectSlider.prototype._onRelease = function() {
  this._lastX = null;
  this._speedometer.stop();

  var velocity = this._speedometer.velocity;
  if (Math.abs(velocity) > 50) {
    this._slider.push(velocity);
    return;
  }

  this._slider.adjust();
};

// Touch handlers
ProjectSlider.prototype._onTouchStart = function(e) {
  if (!e.targetTouches || !e.targetTouches.length) {
    return;
  }

  this._onPress(e.targetTouches[0]);
};

ProjectSlider.prototype._onTouchMove = function(e) {
  if (!e.targetTouches || !e.targetTouches.length) {
    return;
  }

  var touch = e.targetTouches[0];

  if (!this._inTouchScroll) {
    if (Math.abs(this._lastX - touch.pageX) < 30) {
      return;
    }

    this._lastX = touch.pageX;
    this._inTouchScroll = true;
  }

  e.preventDefault();
  this._onMove(touch);
};

ProjectSlider.prototype._onTouchEnd = function(e) {
  if (!this._inTouchScroll) {
    return;
  }

  this._inTouchScroll = false;
  this._onRelease();
};

module.exports = ProjectSlider;
